import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';

export interface getHistoricalDataProps {
    dev_id: string;
    start_time: string;
    end_time: string;
}

export interface getHistoricalDataReturnItemType extends Object {
  id: number;
  dev_id: string;
  timestamp: string;
  las: string;
  lcs: string;
  lzs: string;
  laeqt: string;
  lceqt: string;
  lzeqt: string;
  lapeakt: string;
  lcpeakt: string;
  lzpeakt: string;
  rssi: number;
  power_good: boolean;
  battery: number;
  createdAt: string;
  updatedAt: string;
  lat: null;
  long: null;
  tamper: null;
  temperature: null;
  location: string;
}


export interface getHistoricalDataReturnType {
  status: string;
  total: number;
  data: getHistoricalDataReturnItemType[];
}

export const getHistoricalData = async (props: getHistoricalDataProps): Promise<AxiosResponse<any>> => {
  const data = qs.stringify({ ...props });
    const config: AxiosRequestConfig = {
      method: "post",
      url: "https://monster1.distronix.in:2443/anms/sens/get_historical_data",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
    const response: AxiosResponse<getHistoricalDataReturnType> = await axios(config).then(r => {
        console.log("Response from getHistoricalData: ", r.data);
        return r;
    }).catch((e: any) => {
        const err: AxiosError = e;
        console.log("Error in getting getHistoricalData: ", err.message);
        return e;
    });
    return response;
}
