export type NoiseLevelZoneType =
  | "Commercial"
  | "Industrial zone"
  | "Residential zone"
  | "Silence zone";
export const isNoiseLevelDanger = (
  noiseLevel: number,
  zone: NoiseLevelZoneType
) => {
  const currentHour = new Date().getHours();
  const isDay = currentHour < 22 && currentHour >= 6;
  switch (zone) {
    case "Commercial":
      if (isDay) return noiseLevel < 65;
      return noiseLevel < 55;
    case "Industrial zone":
      if (isDay) return noiseLevel < 75;
      return noiseLevel < 70;
    case "Residential zone":
      if (isDay) return noiseLevel < 55;
      return noiseLevel < 45;
    case "Silence zone":
      if (isDay) return noiseLevel < 50;
      return noiseLevel < 40;
    default:
      return false;
  }
};

export const getNoiseLevelColorCode = (
  noiseLevel: number,
  zone: NoiseLevelZoneType
) => {
  const isOk = isNoiseLevelDanger(noiseLevel, zone);
  return isOk ? "#1d9927" : "red";
};
