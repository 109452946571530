import React from 'react';
import { getDeviceListReturnItemType } from '../api';
import { getNoiseLevelColorCode, NoiseLevelZoneType } from '../util/noise_color_getter';
export interface MapMarker_props {
  lat: number;
  lng: number;
  text: string;
  data: getDeviceListReturnItemType;
  selected: boolean;
  onClick: () => void;
}
export const MapMarker: React.FC<MapMarker_props> = ({
  selected,
  data,
  onClick,
}) => {
  const color = getNoiseLevelColorCode(
    parseFloat(data.laeqt),
    data.zone as NoiseLevelZoneType
  );
  return (
    <div
      className={`h-10 w-10 rounded-full text-black border-2 font-semibold border-gray-800 flex flex-row justify-center items-center cursor-pointer z-50 shadow-md`}
      style={{ backgroundColor: 'green' }}
      onClick={onClick}
    >
      <p className=''>{data.laeqt}</p>
      <span
        className="animate-ping absolute inline-flex h-7 w-7 rounded-full opacity-40"
        style={{ backgroundColor: '#6B7280' }}
      ></span>
    </div>
  );
};
