import axios, { AxiosError, AxiosRequestConfig } from "axios";


export const Logout = async (
  
  refreshToken: string|undefined
  )=> {


    // const refreshToken = useSelector((state: RootState) => state.user.refreshToken);
 
    console.log(refreshToken)

 let config:AxiosRequestConfig = {
  method: 'post',
  url: 'https://api.sht.distronix.in:4443/api/users/logout',
  headers: { 
  
    'x-access-refresh-token': refreshToken
  },
 
};

axios(config)
.then((response) => {
  console.log(response.data);

})
.catch((error: any) => {
  const axiosTypedError: AxiosError = error;
  console.log(axiosTypedError.message);
//   if(axiosTypedError.response?.status === 404) {
//     console.log("Dispatching Logout function");
//     dispatch(logout)
//   }
});
  };