import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import {
  UserInputCredentials,
  UserState,
  login,
  updateLogStatus,
} from "../slices/user.slice";
import { RootState } from "../store";
import GoogleMapReact from "google-map-react";
import { useAppDispatch } from "../util/hooks";
import styles from "./signin.module.css";
import { IBaseComponent } from "./types";
import { MapMarker } from "../components/mapmarker";
import { Login } from "../api/login/login";
import { GetOtp } from "../api/login/getOtp";
import { ForgetPassword } from "../api/login/forgetPassword";
import Crypto from "crypto";

export const SignIn: React.FC<IBaseComponent> = ({ Devices }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UserInputCredentials>();
  const { state } = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  const [err, setErr] = React.useState<string>("");
  const [sus, setSus] = React.useState<string>("");
  const [forgetFlag, setForgetFlag] = React.useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);

  const onSubmit: SubmitHandler<UserInputCredentials> = async (data) => {
    const algorithm = "aes-256-cbc";

    // the cipher function
    const cipher = Crypto.createCipheriv(
      algorithm,
      "12345678123456781234567812345678",
      "1234567812345678"
    );
    //@ts-ignore
    let encryptedData = cipher.update(data.password, "utf-8", "hex");
    // @ts-ignore
    encryptedData += cipher.final("hex");

    if (forgetFlag) {
      const e = await GetOtp(data.userId);
      if (e.status === "success" || e.OTP) {
        setIsSubmitted(true);
        setForgetFlag(false);
        setErr("");
      } else {
        setErr(e.message);
      }
    } else if (isSubmitted) {
      const s = await ForgetPassword(
        data.userId,
        data.newPassword,
        data.confirmPassword,
        data.OTP
      );
      if (s.status === "success") {
        setIsSubmitted(false);
        setSus(s.message);
        setErr("");
      } else {
        setErr("Forbidden Access");
      }
    } else {
      const d: UserState = await Login(data.userId, encryptedData);

      if (d.status === "success") {
        dispatch(login(d));
        setErr("");
      } else {
        // if(typeof(d.message) === 'string'){
        //   setErr(d.message)
        // }
        // else{
        //    setErr(d.message[0].msg)
        // }
      }

      console.log(d);
    }
  };

  const handleForgetClick = () => {
    setErr("");
    setForgetFlag(true);
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setSus("");
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [sus]);

  if (state === "loggedIn") return <Redirect to="/map" />;
  return (
    <div className="flex flex-col flex-1 justify-center items-center">
      <GoogleMapReact
        style={{ flex: 1, width: "100%" }}
        bootstrapURLKeys={{ key: "AIzaSyCxB4NOyF5Du4Ih88qXosmZHq4HVCAG_yk" }}
        defaultCenter={{ lat: 22.860674, lng: 87.353625 }}
        defaultZoom={10}
        resetBoundsOnResize
      >
        {Devices.map((d) => (
          <MapMarker
            lat={parseFloat(d.lat)}
            lng={parseFloat(d.long)}
            key={d.id}
            data={d}
            text={d.location}
            selected={false}
            onClick={() => {}}
          />
        ))}
      </GoogleMapReact>
      <form
        className="flex absolute flex-col justify-center items-stretch p-8 border-2 border-gray-200 rounded-lg bg-blend-overlay bg-white bg-opacity-75 gap-4"
        style={{ minWidth: 400 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col justify-start items-start w-full">
          {err !== "" && (
            <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700 mx-16">
              <p>{err}</p>
            </div>
          )}
          {sus !== "" && (
            <div
              id="alert-3"
              className="flex p-4 mb-4 bg-green-100 rounded-lg dark:bg-green-200 mx-10"
              role="alert"
            >
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-700 dark:text-green-800"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <div className="ml-3 text-sm font-medium text-green-700 dark:text-green-800">
                <p>{sus}</p>
              </div>
            </div>
          )}
          <div className="p-1 text-gray-600 text-lg">User ID</div>
          <input
            type="email"
            id="userId"
            {...register("userId", { required: true })}
            className="rounded-md w-full p-2 text-lg shadow-md"
          />
          <span className="text-xs text-red-500 px-1">
            {!!errors.userId ? errors.userId.message : ""}
          </span>
        </div>
        {!forgetFlag && !isSubmitted && (
          <div className="flex flex-col justify-start items-start w-full">
            <div className="text-lg p-1 text-gray-600">Password</div>
            <input
              type="password"
              id="userId"
              {...register("password", { required: true })}
              className="rounded-md w-full p-2 text-lg shadow-md"
            />
            <span className="text-xs text-red-500 px-1">
              {!!errors.password ? errors.password.message : ""}
            </span>
          </div>
        )}

        {isSubmitted && (
          <>
            <div className="flex flex-col justify-start items-start w-full">
              <div className="text-lg p-1 text-gray-600">New Password</div>
              <input
                type="New Password"
                id="userId"
                {...register("newPassword", { required: true })}
                className="rounded-md w-full p-2 text-lg shadow-md"
              />
              <span className="text-xs text-red-500 px-1">
                {!!errors.password ? errors.password.message : ""}
              </span>
            </div>
            <div className="flex flex-col justify-start items-start w-full">
              <div className="text-lg p-1 text-gray-600">
                Confirm New Password
              </div>
              <input
                type="Confirm New Password"
                id="userId"
                {...register("confirmPassword", { required: true })}
                className="rounded-md w-full p-2 text-lg shadow-md"
              />
              <span className="text-xs text-red-500 px-1">
                {!!errors.password ? errors.password.message : ""}
              </span>
            </div>
            <div className="flex flex-col justify-start items-start w-full">
              <div className="text-lg p-1 text-gray-600">OTP</div>
              <input
                type="OTP"
                id="userId"
                {...register("OTP", { required: true })}
                className="rounded-md w-full p-2 text-lg shadow-md"
              />
              <span className="text-xs text-red-500 px-1">
                {!!errors.password ? errors.password.message : ""}
              </span>
            </div>
          </>
        )}

        <input
          type="submit"
          className={`p-4 bg-gray-300 text-gray-50 rounded-md shadow-md font-semibold font-sans hover:text-gray-200 hover:bg-gray-700 transition-colors duration-500 ${styles.signinbutton}`}
          value={!forgetFlag ? "Submit" : "Get Otp"}
        />
        {!forgetFlag && !isSubmitted && (
          <a
            style={{ marginLeft: "220px" }}
            className="inline-block font-bold text-sm text-blue-500 hover:text-blue-800"
            href="#"
            onClick={handleForgetClick}
          >
            Forget Password?
          </a>
        )}
      </form>
    </div>
  );
};
