import axios, { AxiosError, AxiosRequestConfig } from "axios"



export const GetOtp = async(userName:string) => {

    let data = {
        "userName":userName
    }

  let config:AxiosRequestConfig = {
      method:'post',
      url:'https://api.sht.distronix.in:4443/api/users/getOtp',
      headers:{
          'Content-Type': 'application/json'
      },
      data:data
  }
 const result = await axios(config)
  .then((res) => res.data)
  .catch((e:any)=> {
    e as AxiosError;
    return e.response.data;
  })
 return result
}