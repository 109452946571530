import axios, { AxiosError, AxiosRequestConfig } from "axios"



export const ForgetPassword = async(userName:string,Password:string|undefined,confirmPassword:string|undefined,otp:string|undefined) => {

    let data = {
        "userName":userName,
        "password":Password,
        "confirmPassword":confirmPassword,
        "otp":otp
    }

  let config:AxiosRequestConfig = {
      method:'post',
      url:'https://api.sht.distronix.in:4443/api/users/forgetPassword',
      headers:{
          'Content-Type': 'application/json'
      },
      data:data
  }
 const result = await axios(config)
  .then((res) => res.data)
  .catch((e:any)=> {
    e as AxiosError;
    return e.response.data;
  })
 return result
}