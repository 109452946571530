import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';

export interface getHistoricalDataProps {
    dev_id: string;
    start_time: string;
    end_time: string;
}

export interface getHistoricalDataReturnItemType extends Object {
  id: number;
  dev_id: string;
  timestamp: string;
  las: string;
  lcs: string;
  lzs: string;
  laeqt: string;
  lceqt: string;
  lzeqt: string;
  lapeakt: string;
  lcpeakt: string;
  lzpeakt: string;
  rssi: number;
  power_good: boolean;
  battery: number;
  createdAt: string;
  updatedAt: string;
  lat: null;
  long: null;
  tamper: null;
  temperature: null;
  location: string;
}


export interface getHistoricalDataReturnType {
  status: string;
  total: number;
  data: getHistoricalDataReturnItemType[];
}

export interface getDiagnosticDataReturnItemType extends Object {
  dev_id: string;
  operator: string;
  rssi?: number;
  solar_status?: boolean;
  power_good?: boolean;
  keep_alive: string;
  solar_battery_status?: any;
  created_at: string;
}

export interface getDiagnosticDataReturnType {
  status: string;
  connected_to: string;
  data: getDiagnosticDataReturnItemType[];
}

export const getDiagnosticData = async (props: getHistoricalDataProps): Promise<AxiosResponse<any>> => {

    const config: AxiosRequestConfig = {
      method: "get",
      url: `https://monster1.distronix.in:2443/anms/reports/getDiagReport?dev_id=${props.dev_id}&start_time=${props.start_time}&end_time=${props.end_time}`,
      
    };
    const response: AxiosResponse<getDiagnosticDataReturnType> = await axios(config).then(r => {
        console.log("Response from getDiagnosticData: ", r.data);
        return r;
    }).catch((e: any) => {
        const err: AxiosError = e;
        console.log("Error in getting getDiagnosticData: ", err.message);
        return e;
    });
    return response;
}
