import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Logout } from "../api/login/logout";
import { reset } from "../slices/user.slice";
import { useAppDispatch, useAppSelector } from "../util/hooks";

export const Header = () => {
  const { location } = useHistory();
  const [path, setpath] = useState(location.pathname);
  const dispatch = useAppDispatch();
  const { userRole, state, refreshToken } = useAppSelector((s) => s.user);

  const handleLogout = async () => {
    await Logout(refreshToken);

    setpath("/signin");
    dispatch(reset());
  };

  return (
    <div
      className="flex flex-row justify-between flex-wrap items-center bg-blue-500 text-white text-lg px-4 py-2"
      style={{
        backgroundImage:
          "linear-gradient(90deg, rgb(255, 223, 0) 0%, rgb(0, 126, 139) 100%)",
      }}
    >
      <div className="flex flex-row justify-start gap-4 items-center">
        <img
          src="BPCL_Logo.png"
          alt="BPCLLogo"
          className="max-w-screen-sm h-14 bg-gray-100"
        />
      </div>
      <div className=" text-white drop-shadow-lg filter text-2xl rounded text-center p-2 font-sans font-bold">
        BPCL KR CONTINUOUS NOISE MONITORING SYSTEM
      </div>
      <div className="flex flex-col sm:flex-row justify-end items-center gap-4">
        <Link
          to="/map"
          onClick={(e) => setpath("/map")}
          className={`text-white border-2 p-2 font-sans text-sm hover:bg-white hover:text-blue-500 whitespace-nowrap ${
            path === "/map" ? "border-b-8" : "text-white bg-transparent"
          }`}
        >
          Map View
        </Link>
        <Link
          to="/history"
          onClick={(e) => setpath("/history")}
          className={`text-white border-2 p-2 font-sans text-sm hover:bg-white hover:text-blue-500 ${
            path === "/history" ? "border-b-8" : "text-white bg-transparent"
          }`}
        >
          Grid
        </Link>
        {userRole === "admin" && (
          <Link
            to="/diagnostic"
            onClick={(e) => setpath("/diagnostic")}
            className={`text-white border-2 p-2 font-sans text-sm hover:bg-white hover:text-blue-500 ${
              path === "/diagnostic"
                ? "border-b-8"
                : "text-white bg-transparent"
            }`}
          >
            Diagnostic
          </Link>
        )}

        <Link
          to="/graph"
          onClick={(e) => setpath("/graph")}
          className={`text-white border-2 p-2 font-sans text-sm hover:bg-white hover:text-blue-500 ${
            path === "/graph" ? "border-b-8" : "text-white bg-transparent"
          }`}
        >
          Charts
        </Link>

        {state === "loggedIn" ? (
          <>
            {/* <div className="text-white p-2 font-sans text-sm border-b-4 border-white">
                {username}
              </div> */}
            <Link
              to="/signin"
              onClick={handleLogout}
              className={`text-white border-2 border-red-400 rounded-full p-2 font-sans text-sm hover:bg-red-300 hover:text-black ${
                path === "/signin" ? "border-b-8" : "text-white bg-transparent"
              }`}
            >
              Logout
            </Link>
          </>
        ) : null}
        <div className="flex flex-row justify-start gap-4 items-center">
          <img
            src="logo.png"
            alt="Logo"
            style={{
              width: "100px",
              height: "30px",
              objectFit: "contain",
            }}
          />
        </div>
      </div>
    </div>
  );
};
