import axios, { AxiosError, AxiosRequestConfig } from "axios";


export const Login = async (userName: string, password: string) => {
  let data = { userName: userName, password: password };

  let config: AxiosRequestConfig = {
    method: "post",
    url: "https://api.sht.distronix.in:4443/api/users/login",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const result = await axios(config)
    .then((res) => {
      return res.data;
    })
    .catch((e:any) => {
           e as AxiosError;
           return e.response.data;
    });

  // console.log('result data->',result.response.data)
  return result;
};
