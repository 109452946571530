import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type logState = "loggedIn" | "guest" | "loggingIn";

export type UserInputCredentials = {
  userId: string;
  password: string;
  newPassword?: string;
  confirmPassword?: string;
  OTP?: string;
};

export interface UserState {
  status: string;
  name: string;
  userName: string;
  userRole: string;
  profilePic: string;
  token: string;
  refreshToken: string;
  state: logState;
}

export const initialState: UserState = {
  state: "guest",
  status: "",
  name: "",
  userName: "",
  userRole: "",
  profilePic: "",
  token: "",
  refreshToken: "",
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (
      state: UserState,
      action: PayloadAction<Omit<Required<UserState>, "state">>
    ) => {
      state.name = action.payload.name;
      state.profilePic = action.payload.profilePic;
      state.refreshToken = action.payload.refreshToken;
      state.status = action.payload.status;
      state.userName = action.payload.userName;
      state.userRole = action.payload.userRole;
      state.state = "loggedIn";
      return state;
    },
    updateLogStatus: (state: UserState, action: PayloadAction<logState>) => {
      state.state = action.payload;
      return state;
    },
    reset: (state: UserState, action: PayloadAction<void>) => {
      state = initialState;
      return state;
    },
  },
});

export const { login, reset, updateLogStatus } = UserSlice.actions;
export default UserSlice.reducer;
