import React, { useEffect, useMemo, useRef, useState } from "react";
import { enableRipple } from "@syncfusion/ej2-base";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  ColumnDirective,
  ColumnsDirective,
  Grid,
  GridComponent,
  ToolbarItems,
  ExcelExport,
  Inject,
  Toolbar,
  PdfExport,
  Filter,
  Sort,
  FilterSettingsModel,
  excelHeaderQueryCellInfo,
  Resize,
} from "@syncfusion/ej2-react-grids";
// import Select from "react-select";
import { RotatingLines } from  'react-loader-spinner'

import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import moment from "moment";
import { IBaseComponent } from "./types";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { RootState } from "../store";
import { wbpcb_base64 } from "../util/logo_base64";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  getDiagnosticData,
  getDiagnosticDataReturnItemType,
} from "../api/getDiagnosticData";
import Select from "react-select";

enableRipple(true);

export interface IHistoryDatagridComponent extends IBaseComponent {
  rowIndexValue: React.MutableRefObject<number>;
}
export const DiagnosticDatagrid: React.FC<IHistoryDatagridComponent> = ({
  Devices,
  SelectedDevice,
  setSelectedDevice,
  StartDate,
  setStartDate,
  EndDate,
  setEndDate,
  Data,
  TotalData,
  rowIndexValue,
}) => {
  const gridref = useRef<GridComponent | null>(null);

 
  const [startDateTime, setStartDateTime] = useState<Date | null>(new Date());
  const [endDateTime, setEndDateTime] = useState<Date | null>(new Date());
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [diagnosticData, setDiagnosticData] = useState<
    getDiagnosticDataReturnItemType[]
  >([]);
  const handleStartChange = (date: Date | null) => {
    setStartDateTime(date);
    console.log(date);
  };
  const handleEndChange = (date: Date | null) => {
    setEndDateTime(date);
    console.log(date);
  };

  const toolbar: ToolbarItems[] = [
    "ExcelExport",
    "PdfExport",
    // "CsvExport",
    "Search",
    // "Print",
  ];
  const filterOptions: FilterSettingsModel = {
    type: "Excel",
  };
  const excelHeaderQueryCellInfo = (args: ClickEventArgs) => {
    if (
      (gridref.current?.excelExportModule as any).sheet.images === null ||
      (gridref.current?.excelExportModule as any).sheet.images === undefined
    ) {
      (gridref.current?.excelExportModule as any).sheet.images = [];
    }
    const excelImage: any = {
      image: wbpcb_base64,
      row: 1,
      column: 1,
      lastRow: 2,
      lastColumn: 2,
    };
    (gridref.current?.excelExportModule as any).sheet.images.push(excelImage);
  };
  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (gridref.current === null || !args) return;
    switch (args.item.text) {
      case "Excel Export":
        rowIndexValue.current = 0;
        gridref.current.excelExport({
          fileName: `${SelectedDevice?.location},${
            SelectedDevice?.street
          }-${moment(startDateTime).format("ll")}-${moment(endDateTime).format(
            "ll"
          )}.xlsx`,
          header: {
            headerRows: 7,
            rows: [
              {
                cells: [
                  {
                    colSpan: 12,
                    value: "BPCL",
                    style: {
                      backColor: "#cde6f7",
                      fontColor: "#000000",
                      fontSize: 18,
                      hAlign: "Right",
                      bold: true,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 12,
                    value: "Bharat Petroleum Corporation",
                    style: {
                      backColor: "#cde6f7",
                      fontColor: "#000000",
                      fontSize: 16,
                      hAlign: "Right",
                      bold: true,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 12,
                    value: `Device: ${
                      DropDownListItems.find(
                        (i) => i.value === SelectedDevice?.dev_id
                      )?.label
                    }`,
                    style: {
                      fontSize: 12,
                    },
                  },
                ],
              },
              
              {
                cells: [
                  {
                    colSpan: 12,
                    value: `From ${moment(startDateTime).format(
                      "DD/MM/YYYY hh:mm:ss"
                    )} To ${moment(endDateTime).format("DD/MM/YYYY hh:mm:ss")}`,
                    style: {
                      fontSize: 12,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 12,
                    value: `Downloaded on ${moment().format(
                      "DD-MM-YYYY hh:mm:ss A"
                    )}`,
                    style: {
                      fontSize: 12,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 12,
                    value: DropDownListItems.find(
                      (i) => i.value === SelectedDevice?.dev_id
                    )?.label,
                    style: {
                      backColor: "#204FAC",
                      fontColor: "#ffffff",
                      fontSize: 13,
                      hAlign: "Center",
                    },
                  },
                ],
              },
            ],
          },
        });
        break;
      case "PDF Export":
        rowIndexValue.current = 0;
        gridref.current.pdfExport({
          pageSize: "A3",
          fileName: `${SelectedDevice?.location},${
            SelectedDevice?.street
          }-${moment(startDateTime).format("ll")}-${moment(endDateTime).format(
            "ll"
          )}.pdf`,
          pageOrientation: "Landscape",
          theme: {
            header: {
              bold: true,
              // border: { color: '#64FA50' },
              fontColor: "#000000",
              fontName: "Calibri",
              fontSize: 14,
            },
            record: {
              fontColor: "#000000",
              fontName: "Calibri",
              fontSize: 12,
            },
          },
          header: {
            contents: [
              {
                type: "Image",
                src: wbpcb_base64,
                position: { x: 0, y: 0 },
                size: { height: 100, width: 100 },
              },
              {
                position: { x: 0, y: 100 },
                style: { textBrushColor: "#000000", fontSize: 25 },
                type: "Text",
                value: "BPCL CONTINUOUS NOISE MONITORING SYSTEM",
              },
              {
                position: { x: 0, y: 135 },
                style: { textBrushColor: "#000000", fontSize: 18 },
                type: "Text",
                value: `Created at: ${moment().format("DD-MM-YYYY HH:mm:ss")}`,
              },
              {
                position: { x: 300, y: 135 },
                style: { textBrushColor: "#000000", fontSize: 18 },
                type: "Text",
                value: `From ${moment(startDateTime).format(
                  "DD-MM-YYYY"
                )} To ${moment(endDateTime).format("DD-MM-YYYY")}`,
              },
              {
                position: { x: 0, y: 165 },
                style: { textBrushColor: "#000000", fontSize: 18 },
                type: "Text",
                value: `${
                  DropDownListItems.find(
                    (i) => i.value === SelectedDevice?.dev_id
                  )?.label
                }`,
              },
            ],
            fromTop: 0,
            height: 200,
          },
        });
        break;

    }
  };
  const DropDownListItems: any[] = useMemo(
    () =>
      Devices.map((d) => {
        const item: any = {
          label: `${d.location}, Kochi`,
          value: d.dev_id,
        };
        return item;
      }),
    [Devices]
  );
  const { state } = useSelector((state: RootState) => state.user);

  const loadData = async () => {
  
       setApiLoading(true);

    try {
      const data = await getDiagnosticData({
        dev_id: SelectedDevice?.dev_id || "",
        start_time: moment(startDateTime).format("YYYY-MM-DD HH:mm:ss"),
        end_time: moment(endDateTime).format("YYYY-MM-DD HH:mm:ss"),
      });
      // console.log(data.data);
      const dateData = data.data.data.map((d:getDiagnosticDataReturnItemType,index:number) => ({
        ...d,
        sl_no: index+1,
        //@ts-ignore
        rssi:(((d?.rssi)/31)*100).toFixed(2),
        created_at: moment(d.created_at).format("DD-MM-YYYY h:mma"),
      }))

      setDiagnosticData(dateData);
    } catch (error: any) {
      alert("Error: " + error?.message);
    }
    setApiLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      // This line disable the blue border

      border: state.isFocused ? "1px solid #acacac" : "1px solid #acacac",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        boxShadow: state.isFocused ? 0 : 0,
      },
    }),
  };

  if (state !== "loggedIn") return <Redirect to="/signin" />;
  return (
    <div className="flex flex-col justify-start items-center w-full">
      <div className="flex flex-col sm:flex-row justify-start items-center w-full gap-6 my-3 ">
      <Select
          className="basic-single w-120 text-black ml-2"
          classNamePrefix="select"
          styles={customStyles}
          isSearchable
          name="color"
          placeholder={
            DropDownListItems.find((i) => i.value === SelectedDevice?.dev_id)
              ?.label
          }
          onChange={(e: any) => {
            setSelectedDevice(Devices.find((d) => d.dev_id === e.value));
          }}
          options={DropDownListItems}
        />
        <div className="w-2/12">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Start Date & Time"
              value={startDateTime}
              onChange={handleStartChange}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>
        <div className="w-2/12">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="End Date & Time"
              value={endDateTime}
              onChange={handleEndChange}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>
        <button
          type="button"
          onClick={() => loadData()}
          className="inline-flex justify-center items-center gap-1 bg-blue-500 hover:bg-blue-400 text-white font-bold py-1.5 px-2 border-b-4 border-blue-700 hover:border-blue-500 rounded"
          disabled={!!apiLoading}
        >
          <RotatingLines
            width="20"
            strokeColor="#ffffff"
            strokeWidth="3"
            animationDuration="1"
            visible={!!apiLoading}
           />
         <span>{apiLoading?'Loading':'Submit'}</span>
        </button>

        <div className="flex flex-1 justify-end items-center pr-2">
          Showing {diagnosticData.length} rows
        </div>
      </div>
      <div
        style={{ height: "75vh", width: "99%" }}
        className="w-screen overflow-y-scroll mb-10 border-2 border-gray-600 ml-5 mr-5"
      >
        <GridComponent
          dataSource={diagnosticData}
          ref={(g) => (gridref.current = g)}
          toolbar={toolbar}
          filterSettings={filterOptions}
          toolbarClick={toolbarClick}
          allowExcelExport
          allowPdfExport
          allowFiltering
          allowSorting
          allowTextWrap
          allowResizing
          showColumnMenu={false}
          enableAltRow
          dataBound={(e) => {
            rowIndexValue.current = 0;
          }}
          beforeDataBound={(e) => {
            rowIndexValue.current = 0;
          }}
          // @ts-ignore
          excelHeaderQueryCellInfo={excelHeaderQueryCellInfo}
        >
          <ColumnsDirective>
            <ColumnDirective field="sl_no" headerText="Sl no." width={110} />
            <ColumnDirective field="rssi" headerText="RSSI (%)" width={120} />
            <ColumnDirective
              field="solar_status"
              textAlign="Left"
              headerText="Solar Status"
              width={170}
            />

            <ColumnDirective
              field="power_good"
              textAlign="Left"
              headerText="Power Good"
              width={170}
            />
            <ColumnDirective
              field="operator"
              textAlign="Left"
              headerText="Network Type"
              width={180}
            />

            <ColumnDirective
              field="keep_alive"
              textAlign="Left"
              headerText="Keep Alive"
              width={140}
            />
            {/* <ColumnDirective
              field="solar_battery_status"
              textAlign="Left"
              headerText="Solar Battery Status"
              width={200}
            /> */}
            <ColumnDirective
              field="created_at"
              textAlign="Left"
              headerText="Timestamp"
              width={180}
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort, Resize]} />
        </GridComponent>
      </div>
    </div>
  );
};
